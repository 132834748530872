<template>
  <div>
    <!-- 批量融合 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
      <div class="content-wrap">
        <div class="btn-wrap cfx">
          <el-button size="small" class="button_import" v-if="OA.includes('user:cardOrder:lead')"><base-import el-Txt="批量操作" importUrl="net/admin/batchFuse/import" @finish="refresh"></base-import></el-button>
          <el-button size="small" class="el-icon-download"><el-link :href="BaseUrl + 'net/admin/batchFuse/noLogin/downModule'" class="button_download">下载模板</el-link></el-button>
        </div>

        <!--列表-->
        <paged-table
          :data="storeList"
          :total="storeTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
          @refresh="refresh"
          @selectionChange="setSelection"
        >
          <el-table-column prop="id" show-overflow-tooltip label="批次号"></el-table-column>
          <el-table-column show-overflow-tooltip label="学校">
            <template slot-scope="scope" v-if="scope.row.extend">
              <span>{{ scope.row.extend.schoolName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="userName" show-overflow-tooltip label="操作人">
            <template slot-scope="scope" v-if="scope.row.extend">
              <span>{{ scope.row.extend.userName }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="addTime" show-overflow-tooltip label="导入时间">
          </el-table-column>
          <el-table-column prop="totalNum" show-overflow-tooltip label="导入数量"></el-table-column>
          <el-table-column prop="succNum" show-overflow-tooltip label="成功数量"></el-table-column>
          <el-table-column prop="failNum" show-overflow-tooltip label="失败数量"></el-table-column>
          <el-table-column show-overflow-tooltip label="日志下载">
            <template slot-scope="scope">
            <el-link :href="BaseUrl + 'net/admin/batchFuse/noLogin/downLoadLog?id='+ scope.row.id" class="button_download"><el-button size="small" type="success" class="el-icon-download">下载</el-button></el-link>
            </template>
          </el-table-column>
        </paged-table>
      </div>
    </area-tree>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from "@/common/utils";
export default {
  name: "cardOrder",
  components: {
    PagedTable,
    AreaTree,
    BaseImport
  },
  mixins: [pageMixin, provinces, download],
  data() {
    return {
      url: '',
      BaseUrl,
      queryParams: {
        // cooperate: null,
        // schoolName: null,
      },
      schoolType: [],
      
      pageApi: "batchFuseList",
    };
  },
  computed: {
    ...mapState(["OA"]),
    ...mapState("cardOrder", ["storeList", "storeTotal"]),
  },
  methods: {
    ...mapActions("cardOrder",["batchFuseList", "batchFuseImport"]),
    ...mapActions("commonOptions", ["getSchoolCombo"]),
    setSelection(list){
      log(list)
    },
    handleQuery(data) {
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
        this.queryParams.city = null;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
        this.queryParams.area = null;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
      if ((data.id + "").length <9) {
        this.queryParams.schoolName = data.name;
      }
      this.refresh();
    },
  },
  async mounted() {
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
    if(this.getPageParams(this.$options.name))
    mergeObject(this.queryParams, this.getPageParams(this.$options.name))
    this.refresh()
    this.schoolType = await this.commonDictGetDictFPid({pid: 104001})
    this.schoolOptions = await this.getSchoolCombo({})
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/common.scss";
.button_import {
  padding: 0;
  font-size: 16px;
  margin-right: 5px;
}
.button_download{
  font-size: 12px;
}
</style>
